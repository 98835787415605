<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>CƠ QUAN CHỦ QUẢN:<br/> SỞ THÔNG TIN VÀ TRUYỀN THÔNG TỈNH TUYÊN QUANG</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Điện thoại</span>
                            <a href="tel:02183.898.678">02076 251 858</a>
                        </li>
                        <li>
                            <i class="flaticon-architecture"></i>
                            <span>Địa chỉ</span>
                            <a target="_blank" href="https://maps.app.goo.gl/Uh6gb48uSPvUgvs99">Đường Tân Trào, Phường Minh Xuân, Thành phố Tuyên Quang.</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Email</span>
                            <a href="mailto:bcxbtq@tuyenquang.gov.vn"> bcxbtq@tuyenquang.gov.vn</a>
                        </li>
                        <li>
                            <i class="fas fa-eye"></i>
                            <span>Lượt truy cập</span>
                            <div>
                                <p href="https://www.hitwebcounter.com" target="_blank">
                                    <img src="https://hitwebcounter.com/counter/counter.php?page=14546188&style=0006&nbdigits=9&type=page&initCount=0" title="Counter Widget" Alt="Visit counter For Websites"   border="0" /></p>               
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Vị trí của tỉnh</h3>
                    <ul class="footer-quick-links">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d946369.4666029618!2d104.56367106601854!3d22.097143821684494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3134adfba5491ee1%3A0x7f5401719284787f!2zVHV5w6puIFF1YW5nLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1702281234410!5m2!1svi!2s" 
                            width="400" height="250" style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
        </div>
    </div>
</section>
<!-- End Footer Area -->