<app-navbar></app-navbar>

<!-- Quản trị Hệ thống Area -->
<div class="container-fluid">
    <div class="row justify-content-center">
        <div
            class="col-sm-10 col-md-4 col-lg-4 d-flex justify-content-center mt-3"
        >
            <div
                class="quan-tri-hthong-left bg-portal text-center justify-content-center"
            >
                <div class="qtht-title mb-5">
                    <h4 style="color: green;">KHO DỮ LIỆU DÙNG CHUNG</h4>
                </div>
                <div class="d-flex justify-content-center py-2" *ngIf="showAppPortal('APPQUANTRIDANHMUC')">
                    <div class="quan-tri-chung-left text-start px-3">
                        <a href="{{ WEB_ADMIN_DMDC }}">Dữ liệu danh mục dùng chung</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-2" *ngIf="showAppPortal('APPUBND')">
                    <div class="quan-tri-chung-left text-start px-3">
                        <a href="{{ WEB_ADMIN_CONGBAODT }}">Dữ liệu thủ tục hành chính</a>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="col-sm-10 col-md-4 col-lg-4 d-flex justify-content-center mt-3"
        >
            <div
                class="quan-tri-hthong-right bg-portal text-center justify-content-center"
            >
                <div class="qtht-title mb-5">
                    <h4 style="color: var(--mainColor);">KHO DỮ LIỆU SỞ BAN NGÀNH</h4>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPCONGTHUONG')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_CT }}">Sở Công thương</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPTTTT')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_TTTT }}">Sở Thông tin và Truyền thông</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPNONGNGHIEP')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_NN }}">Sở Nông Nghiệp và Phát triển nông thôn</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPLAODONG')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_LDTBXH }}">Sở Lao động, Thương binh và xã hội</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPTAICHINH')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_TC }}">Sở Tài chính</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPNOIVU')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_NV }}">Sở Nội vụ</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPXAYDUNG')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_XD }}">Sở Xây dựng</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPVHTT')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_VHTT }}">Sở Văn hóa, thể thao và du lịch</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPKHCN')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_KHCN }}">Sở Khoa học và Công nghệ</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPTUPHAP')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_TP }}">Sở Tư pháp</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPGIAODUCDAOTAO')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_GDDT }}">Sở Giáo dục và Đào tạo</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPYTE')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_YT }}">Sở Y tế</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPNGOAIVU')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_NV }}">Sở Ngoại vụ</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center py-1" *ngIf="showAppPortal('APPTNMT')">
                    <div class="quan-tri-chung-right text-start px-3">
                        <a href="{{ WEB_ADMIN_TNMT }}">Sở Tài nguyên và Môi trường</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end Quản trị Hệ thống -->

<div style="position: relative; max-width: 95vw">
    <div class="shape-img2">
        <img src="{{ path }}/assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="{{ path }}/assets/img/shape/shape3.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="{{ path }}/assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="{{ path }}/assets/img/shape/shape5.png" alt="image" />
    </div>
    <div class="shape-img7">
        <img src="{{ path }}/assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape-img8">
        <img src="{{ path }}/assets/img/shape/shape8.png" alt="image" />
    </div>
    <div class="shape-img9">
        <img src="{{ path }}/assets/img/shape/shape9.png" alt="image" />
    </div>
    <div class="shape-img10">
        <img src="{{ path }}/assets/img/shape/shape10.png" alt="image" />
    </div>
</div>
