import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { HomePortalComponent } from './components/pages/home-portal/home-portal.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { NavbarStyleTwoComponent } from './components/layouts/navbar-style-two/navbar-style-two.component';
import { NavbarStyleThreeComponent } from './components/layouts/navbar-style-three/navbar-style-three.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbToastrModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_QTHT_URL } from './common/app-qtht.service';
import { environment } from 'src/environments/environment';
import { MatTabsModule } from '@angular/material/tabs';
import { CookieModule } from 'ngx-cookie';
import { UserInforComponent } from './components/pages/user-infor/user-infor.component';
import { ChangePasswordComponent } from './components/pages/change-password/change-password.component';
import { AccessHistoryComponent } from './components/pages/access-history/access-history.component';
import { MdPaginationComponent } from './components/pagination/pagination.component';
import { AlertModule } from './components/layouts/_alert';
import { MultiAlertsComponent } from './components/layouts/multi-alerts';
import { DangKyChiaSeComponent } from './components/pages/dang-ky-chia-se/dang-ky-chia-se.component';
import { LoginUserComponent } from './components/pages/login-user/login-user.component';
import { LoginAdminComponent } from './components/pages/login-admin/login-admin.component';
import { AdminPortalComponent } from './components/pages/admin-portal/admin-portal.component';
import { ChartModule } from '@progress/kendo-angular-charts';
import { AuthInterceptor } from './common/interceptors/auth.interceptor';
import { CoreModule } from './common/core.module';
import { QthtComponent } from './components/pages/qtht/qtht.component';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    ServicesComponent,
    NotFoundComponent,
    HomePortalComponent,
    LoginComponent,
    RegisterComponent,
    ServicesStyleTwoComponent,
    ServicesStyleThreeComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    UserInforComponent,
    ChangePasswordComponent,
    AccessHistoryComponent,
    MdPaginationComponent,
    UserInforComponent,
    MultiAlertsComponent,
    DangKyChiaSeComponent,
    LoginUserComponent,
    LoginAdminComponent,
    AdminPortalComponent,
    QthtComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxScrollTopModule,
    NgSelectModule,
    NoopAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    HttpClientModule,
    NbToastrModule.forRoot(),
    CookieModule.forRoot(),
    CoreModule.forRoot(),
    MatTabsModule,
    AlertModule,
    ChartModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
