<ul class="pagination">
    <li class="page-item">
        <div class="d-flex flex-nowrap px-2">
            <ng-select style="width: 150px;" name="lstTest" placeholder="Số dòng" [items]="lstRecordInPage" bindValue="Value"
                bindLabel="Text" [multiple]="false" [closeOnSelect]="true"
                [(ngModel)]="selectRecordInPage" (change)="changePerPage()"
                [clearOnBackspace]="false" [clearSearchOnAdd]="true" required>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    {{item.Text}}
                </ng-template>
            </ng-select>
        </div>
    </li>
    <li class="page-item">
        <a class="page-link page-link-customs" href="javascript:void(0);" (click)="pageFirst()">««</a>
    </li>
    <li class="page-item">
        <a class="page-link page-link-customs" href="javascript:void(0);"(click)="previous();">«</a>
    </li>
    <li class="ngb-custom-pages-item" style="border-right: 1px solid #dddddd">
        <div class="d-flex flex-nowrap px-2">
            <label id="paginationInputLabel" for="paginationInput" class="col-form-label mr-2 ml-1">Trang</label>
            <input type="text" inputmode="numeric" pattern="[0-9]*" id="paginationInput" [(ngModel)]="currentPage"
            aria-labelledby="paginationInputLabel paginationDescription" class="custom-pages-input" style="width: 5rem; height: 36px; margin-left: 10px;"
            (blur)="changeNumberPage()">
            <span id="paginationDescription" class="col-form-label text-nowrap px-2"> trong {{totalPages}}</span>
        </div>
    </li>
    <li class="page-item">
        <a class="page-link page-link-customs" href="javascript:void(0);" (click)="next()">»</a>
    </li>
    <li class="page-item">
        <a class="page-link page-link-customs" href="javascript:void(0);" (click)="pageLast()">»»</a>
    </li>
</ul>