import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppQthtApiService, DangkychiaseEntity, SysuserEntity } from 'src/app/common/app-qtht.service';
import { CommonService } from 'src/app/common/common.service';
import { SUCCESS_RESPONSE } from 'src/app/common/contants';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dang-ky-chia-se',
    templateUrl: './dang-ky-chia-se.component.html',
    styleUrls: ['./dang-ky-chia-se.component.scss']
})
export class DangKyChiaSeComponent implements OnInit{ 
    constructor(
        private service: AppQthtApiService,
        private commonService: CommonService,
        private router: Router,
    ){}
    target = new DangkychiaseEntity();
    path: string = environment.APP_HOME_PATH;
    ngOnInit(): void {
        
    }

    isleft: string = 'personal';
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    toggleForm(formType: string) {
      if (this.isleft !== formType) {
          this.isleft = formType;
      }
    }

    save(loai){
        this.target.loai = loai;
        this.target.status = 1;
        this.target.ngaydangky = new Date();
        this.target.dieukhoan = 1;
        this.service.appQthtApiV1DangkychiaseInsertdata(this.target).subscribe((res) => {
        if (res.code === SUCCESS_RESPONSE) {
            this.router.navigate(['/']).then(() => {
                this.commonService.toastrSuccess('Chia sẻ thành công !');
                });
        } else {
            this.commonService.toastrSuccess('Đăng ký không thành công!');
        }});
    }
}
