import { Component, OnInit, inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/common/auth.service";
import { CommonService } from "src/app/common/common.service";
import { UserInforComponent } from "../../pages/user-infor/user-infor.component";
import { AlertService } from "../_alert";
import { UNITCODE_CLIENT } from "src/app/common/contants";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class NavbarComponent implements OnInit {
    location: any;
    navbarClass: any;
    path: string = environment.APP_HOME_PATH;

    WEB_QTHT: string = environment.WEB_QTHT;
    WEB_KHCN: string = environment.WEB_KHCN;
    WEB_LDTBXH: string = environment.WEB_LDTBXH;
    WEB_NV: string = environment.WEB_NV;
    WEB_TC: string = environment.WEB_TC;
    WEB_TTTT: string = environment.WEB_TTTT;
    WEB_TP: string = environment.WEB_TP;
    WEB_VHTT: string = environment.WEB_VHTT;
    WEB_GDDT: string = environment.WEB_GDDT;
    WEB_NN: string = environment.WEB_NN;
    WEB_XD: string = environment.WEB_XD;
    WEB_YT: string = environment.WEB_YT;
    WEB_CT: string = environment.WEB_CT;
    WEB_TNMT: string = environment.WEB_TNMT;
    WEB_NGOAIVU: string = environment.WEB_NGOAIVU;
    WEB_CONGBAODT: string = environment.WEB_CONGBAODT;
    WEB_DMDC: string = environment.WEB_DMDC;
    constructor(
        private router: Router,
        location: Location,
        private authService: AuthService,
        private commonService: CommonService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.location = this.router.url;
                if (
                    this.location == "/index-8" ||
                    this.location == "/index-9" ||
                    this.location == "/index-11"
                ) {
                    this.navbarClass = "navbar-area navbar-style-two";
                } else {
                    this.navbarClass =
                        " navbar-area navbar-area-with-position-relative";
                }
            }
        });
    }

    user = this.authService.getUser();

    ngOnInit(): void {
        this.checkAdmin();
    }
    openNewTab(url: string) {
        window.open(url, "_blank");
    }
    dangXuat() {
        this.authService.logout();
        this.user = null;
    }

    checkAdmin() {
        return this.user && this.user.UnitCode && this.user.UnitCode != UNITCODE_CLIENT ? true : false;
    }
}
