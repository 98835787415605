<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="karlson-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/" style="display: contents;"><img src="{{path}}/assets/img/svg/Logo_Tuyen_Quang.svg" alt="logo" class="logo">&nbsp;
                    <p class="logotext">Cổng cơ sở dữ liệu dùng chung tỉnh Tuyên Quang</p>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown" *ngIf="!checkAdmin()">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Lĩnh vực <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_NN}}" routerLinkActive="active" class="nav-link">Lĩnh vực Nông nghiệp và phát triển nông thôn</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_LDTBXH}}" routerLinkActive="active" class="nav-link">Lĩnh vực Lao động, thương binh và xã hội</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_NV}}" routerLinkActive="active" class="nav-link">Lĩnh vực Nội vụ</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_XD}}" routerLinkActive="active" class="nav-link">Lĩnh vực Xây dựng</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_VHTT}}" routerLinkActive="active" class="nav-link">Lĩnh vực Văn hóa, thể thao và du lịch</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_TC}}" routerLinkActive="active" class="nav-link">Lĩnh vực Tài chính</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_TP}}" routerLinkActive="active" class="nav-link">Lĩnh vực Tư pháp</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_YT}}" routerLinkActive="active" class="nav-link">Lĩnh vực Y tế</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_KHCN}}" routerLinkActive="active" class="nav-link">Lĩnh vực Khoa học và Công nghệ</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_TTTT}}" routerLinkActive="active" class="nav-link">Lĩnh vực Thông tin và Truyền thông</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_GDDT}}" routerLinkActive="active" class="nav-link">Lĩnh vực Giáo dục đào tạo</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_TNMT}}" routerLinkActive="active" class="nav-link">Lĩnh vực Tài Nguyên và Môi trường </a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_CT}}" routerLinkActive="active" class="nav-link">Lĩnh vực Công Thương </a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_NGOAIVU}}" routerLinkActive="active" class="nav-link">Lĩnh vực Ngoại vụ </a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_CONGBAODT}}" routerLinkActive="active" class="nav-link">Công báo điện tử </a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="{{WEB_DMDC}}" routerLinkActive="active" class="nav-link">Danh mục dùng chung & Dữ liệu số hóa TTHC</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <a  (click)="openNewTab('https://dichvucong.tuyenquang.gov.vn/')"  routerLinkActive="active" class="nav-link pointer">Dịch vụ công trực tuyến</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Tiện ích <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/hdaddacc" routerLinkActive="active" class="nav-link">Hướng dẫn tạo tài khoản</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/hdsearch" routerLinkActive="active" class="nav-link">Hướng dẫn tìm kiếm dữ liệu</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/hdtdv" routerLinkActive="active" class="nav-link">Hướng dẫn tạo dịch vụ</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="!user"  routerLink="/login" routerLinkActive="active" class="nav-link styleTimes">Đăng nhập</a>
                            <a *ngIf="user" class="nav-link dropdown-toggle styleTimes" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="width: auto;">
                                Xin chào: {{user.FullName}}
                                <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul *ngIf="user" class="dropdown-menu">
                                <li class="nav-item styleTimes">
                                    <a routerLink="/userInfor" routerLinkActive="active" class="nav-link">Thông tin tài khoản</a>
                                </li>
                                <li class="nav-item styleTimes">
                                    <a routerLink="/change-password" routerLinkActive="active" class="nav-link">Đổi mật khẩu</a>
                                </li>
                                <li class="nav-item styleTimes">
                                    <a routerLink="/access-history" routerLinkActive="active" class="nav-link">Lịch sử truy cập hệ thống</a>
                                </li>
                                <li class="nav-item styleTimes">
                                    <a (click)="dangXuat()" href="" routerLinkActive="active" class="nav-link">Đăng xuất</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="others-options">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
