import { Component, OnInit, OnDestroy } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
    ActivatedRoute
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy
} from "@angular/common";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpParams } from "@angular/common/http";
import { AppQthtApiService } from "./common/app-qtht.service";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    path: string = environment.APP_HOME_PATH;
    navigationSubscription: any;
    constructor(
        private router: Router,
        private _activatedRoute:ActivatedRoute,
        private QthtApiService: AppQthtApiService,
    ) {}

    async ngOnInit() {
        this.recallJsFuntions();
    }

    getParamValueQueryString( paramName ) {
        const url = window.location.href;
        let paramValue;
        if (url.includes('?')) {
          const httpParams = new HttpParams({ fromString: url.split('?')[1] });
          paramValue = httpParams.get(paramName);
        }
        return paramValue;
      }

    recallJsFuntions() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                $(".preloader").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    event =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe(event => {
                $.getScript(this.path + "/assets/js/custom.js");
                $(".preloader").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
}
