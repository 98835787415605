import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  AppQthtApiService
} from '../../../common/app-qtht.service';

@Component({
  selector: 'app-home-portal',
  templateUrl: './home-portal.component.html',
  styleUrls: ['./home-portal.component.scss']
})
export class HomePortalComponent implements OnInit {

  constructor(
    private api: AppQthtApiService
  ) { }

  // public categoryAxis = {
  //   max: 7,
  //   labels: { rotation: 'auto' }
  // };

  // public valueAxis = {
  //   labels: {
  //     format: "#",
  //   },
  // };

  path: string = environment.APP_HOME_PATH;
  
  WEB_QTHT: string = environment.WEB_QTHT;
  WEB_KHCN: string = environment.WEB_KHCN;
  WEB_LDTBXH: string = environment.WEB_LDTBXH;
  WEB_NV: string = environment.WEB_NV;
  WEB_TC: string = environment.WEB_TC;
  WEB_TTTT: string = environment.WEB_TTTT;
  WEB_TP: string = environment.WEB_TP;
  WEB_VHTT: string = environment.WEB_VHTT;
  WEB_GDDT: string = environment.WEB_GDDT;
  WEB_NN: string = environment.WEB_NN;
  WEB_XD: string = environment.WEB_XD;
  WEB_YT: string = environment.WEB_YT;
  WEB_CT: string = environment.WEB_CT;
  WEB_TNMT: string = environment.WEB_TNMT;
  WEB_NGOAIVU: string = environment.WEB_NGOAIVU;
  WEB_CONGBAODT: string = environment.WEB_CONGBAODT;
  WEB_DMDC: string = environment.WEB_DMDC;

  lstdata = [];
  lstDataThongKeDonVi = [];
  lstDataThongKeTheoNam = [];
  lstDataThongKeTheoLinhVuc = [];


  ngOnInit(): void {
    this.LoadData();
  }

  public categoryAxis = {
    max: 7,
    labels: { rotation: 'auto' }
  };

  public valueAxis = {
    labels: {
      format: "#",
    },
  };

  LoadData(){
    this.api.appQthtApiV1SysUserGetThongKeTheoDonVi().subscribe(res => {
      if(res.code == "Success"){
        this.lstDataThongKeDonVi = res.data;
      }
    });
    this.api.appQthtApiV1SysUserGetThongKeTheoNam().subscribe(res => {
      if(res.code == "Success"){
        this.lstDataThongKeTheoNam = res.data;
      }
    });
    this.api.appQthtApiV1SysUserGetThongKeTheoLinhVuc().subscribe(res => {
      if(res.code == "Success"){
        this.lstDataThongKeTheoLinhVuc = res.data;
      }
    });
  }


}
