import { Component, OnInit } from '@angular/core';
import { IListAppModel } from 'src/app/common/app-qtht.service';
import { SUCCESS_RESPONSE } from 'src/app/common/contants';
import { environment } from 'src/environments/environment';
import { HomeDemoSixService } from '../home-portal/home-portal.service';

@Component({
  selector: 'app-qtht',
  templateUrl: './qtht.component.html',
  styleUrls: ['./qtht.component.scss']
})
export class QthtComponent implements OnInit {

  constructor(
    private service: HomeDemoSixService
  ) { }

  listApp: IListAppModel[] = [];

  ngOnInit() {
    this.getListAdminMenu();
  }
  path: string = environment.APP_HOME_PATH;
  
  WEB_ADMIN_CT: string = environment.WEB_ADMIN_CT;
  WEB_ADMIN_QTHT: string = environment.WEB_ADMIN_QTHT;
  WEB_ADMIN_KHCN: string = environment.WEB_ADMIN_KHCN;
  WEB_ADMIN_LDTBXH: string = environment.WEB_ADMIN_LDTBXH;
  WEB_ADMIN_NV: string = environment.WEB_ADMIN_NV;
  WEB_ADMIN_TC: string = environment.WEB_ADMIN_TC;
  WEB_ADMIN_TTTT: string = environment.WEB_ADMIN_TTTT;
  WEB_ADMIN_TP: string = environment.WEB_ADMIN_TP;
  WEB_ADMIN_VHTT: string = environment.WEB_ADMIN_VHTT;
  WEB_ADMIN_GDDT: string = environment.WEB_ADMIN_GDDT;
  WEB_ADMIN_XD: string = environment.WEB_ADMIN_XD;
  WEB_ADMIN_YT: string = environment.WEB_ADMIN_YT;
  WEB_ADMIN_TNMT: string = environment.WEB_ADMIN_TNMT;
  WEB_ADMIN_NN: string = environment.WEB_ADMIN_NN;
  WEB_ADMIN_NGOAIVU: string = environment.WEB_ADMIN_NGOAIVU;
  WEB_ADMIN_CONGBAODT: string = environment.WEB_ADMIN_CONGBAODT;
  WEB_ADMIN_DMDC: string = environment.WEB_ADMIN_DMDC;

  getListAdminMenu() {
    return this.service.getListMenuAdmin().subscribe((res) => {
      if(res.code == SUCCESS_RESPONSE){
        this.listApp = res.data
      }
    })
  }

  showAppPortal(madinhdanh: string) {
    const appPortal = this.listApp.find((app) => app.madinhdanhApp == madinhdanh);
    const showApp = appPortal ? true : false
    return showApp
  }

}
