<app-navbar></app-navbar>

<div class="container">
    <div class="form-content_register">
        <div class="form-header">
            <h3>Thông tin tài khoản</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> -->
        </div>
        <form #frm="ngForm" (ngSubmit)="save()">
            <div class="row">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div class="form-group">
                            <label><strong>Ảnh đại diện</strong></label><br>
                            <div style="position: relative; display: inline-block;">
                                <img *ngIf="target.imgavatar" [src]="target.imgavatar" alt=""
                                    style="max-height: 150px;">
                                <img *ngIf="!target.imgavatar" src="{{path}}/assets/img/blank_user.jpg" alt=""
                                    style="max-height: 150px;">
                                <i *ngIf="isEditing" onclick="document.getElementById('fileInput').click();"
                                    class="fas fa-edit" style="position: absolute; top: 5px; right: -10px;"
                                    title="Đổi ảnh đại diện"></i>
                                <input type="file" id="fileInput" style="display: none;"
                                    (change)="showSelectedImage($event)" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div class="form-group" *ngIf="base64">
                            <label><strong>Ảnh đại diện mới</strong></label><br>
                            <div style="position: relative; display: inline-block;">
                                <img *ngIf="base64" [src]="base64" alt="" style="max-height: 150px;">
                                <i *ngIf="base64" class="fas fa-trash-can" style="position: absolute; top: 0; right: 0;"
                                    title="Hủy bỏ" (click)="base64 = ''"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                    <div class="form-group">
                        <label class="required"><strong>Tên đăng nhập</strong></label>
                        <input autocomplete="off" type="text" class="form-control" placeholder="Tên đăng nhập"
                            name="username" [(ngModel)]="target.username" maxlength="50" readonly>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                    <div class="form-group">
                        <label class="required"><strong *ngIf="target.loaiuser == 1">Họ và tên</strong><strong *ngIf="target.loaiuser == 2">Tên tổ chức</strong></label>
                        <input [disabled]="!isEditing" autocomplete="off" type="text" class="form-control"
                            [placeholder]="target.loaiuser == 1 ? 'Họ và tên' : 'Tên tổ chức'" name="fullname" [(ngModel)]="target.fullname" #fullname="ngModel"
                            maxlength="50" required>
                    </div>
                    <div *ngIf="fullname.errors && (fullname.dirty || fullname.touched)" class="alert alert-danger">
                        <div [hidden]="!fullname.errors.required" *ngIf="target.loaiuser == 1">Họ và tên là bắt buộc</div>
                        <div [hidden]="!fullname.errors.required" *ngIf="target.loaiuser == 2">Tên tổ chức là bắt buộc</div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row" *ngIf="target.loaiuser == 1">
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                    <div class="form-group">
                        <label><strong>Gới tính</strong></label>
                        <ng-select [disabled]="!isEditing" class="custom-ng-select" [items]="listSex"
                            [(ngModel)]="target.gioitinh" bindLabel="text" bindValue="value"
                            name="gioitinh"></ng-select>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                    <div class="form-group">
                        <label class="required"><strong>Số cccd / hộ chiếu</strong></label>
                        <input autocomplete="off" [disabled]="!isEditing" type="text" class="form-control"
                            placeholder="Số cccd / hộ chiếu" maxlength="20" name="socmnd" #socmnd="ngModel"
                            [(ngModel)]="target.socmnd" required>
                    </div>
                    <div *ngIf="socmnd.errors && (socmnd.dirty || socmnd.touched)" class="alert alert-danger">
                        <div [hidden]="!socmnd.errors.required">Nhập số cccd / hộ chiếu</div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="target.loaiuser == 2">
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                    <div class="form-group">
                        <label class="required"><strong>Họ và tên người liên hệ</strong></label>
                        <input autocomplete="off" [disabled]="!isEditing" type="text" class="form-control" placeholder="Họ và tên người liên hệ"
                            name="nglienhe" [(ngModel)]="target.nglienhe" #nglienhe="ngModel" maxlength="50"
                            required>
                    </div>
                    <div *ngIf="nglienhe.errors && (nglienhe.dirty || nglienhe.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!nglienhe.errors.required">Họ và tên người liên hệ là bắt buộc</div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                    <div class="form-group">
                        <label class="required"><strong>Mã số thuế</strong></label>
                        <input [disabled]="!isEditing" autocomplete="off" type="text" class="form-control" placeholder="Mã số thuế"
                            name="msthue" [(ngModel)]="target.msthue" #msthue="ngModel" maxlength="50">
                    </div>
                    <div *ngIf="msthue.errors && (msthue.dirty || msthue.touched)"
                        class="alert alert-danger">
                        <div [hidden]="!msthue.errors.required">Mã số thuế là bắt buộc</div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                    <div class="form-group">
                        <label><strong>Email</strong></label>
                        <input type="email" [disabled]="!isEditing" class="form-control" placeholder="Email"
                            [pattern]="emailPattern" name="email" [(ngModel)]="target.email" maxlength="200">
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                    <div class="form-group">
                        <label class="required"><strong>Số điện thoại</strong></label>
                        <input type="text" [disabled]="!isEditing" class="form-control" placeholder="Số điện thoại"
                            name="phone" [(ngModel)]="target.phone" maxlength="11" #phone="ngModel" required>
                    </div>
                    <div *ngIf="phone.errors && (phone.dirty || phone.touched)" class="alert alert-danger">
                        <div [hidden]="!phone.errors.required">Nhập số điện thoại</div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row ">
                <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6 text-end form-footer" *ngIf="!isEditing">
                    <button type="submit" class="default-btn" (click)="editForm()">Thay đổi thông tin</button>
                </div>
                <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6 text-end form-footer" *ngIf="isEditing">
                    <button type="submit" class="default-btn" [disabled]="frm.invalid">Lưu thông tin</button>
                </div>
                <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6 text-end form-footer">
                    <button type="submit" routerLink="/change-password" class="default-btn">Đổi mật khẩu</button>
                </div>
            </div>
            <br />
        </form>
    </div>
</div>
<div style="position: relative; max-width: 95vw;">
    <div class="shape-img2"><img src="{{path}}/assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="{{path}}/assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="{{path}}/assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="{{path}}/assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="{{path}}/assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="{{path}}/assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="{{path}}/assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="{{path}}/assets/img/shape/shape10.png" alt="image"></div>
</div>

<!-- End Signup Area -->