import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ServicesComponent } from './components/pages/services/services.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { HomePortalComponent } from './components/pages/home-portal/home-portal.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { UserInforComponent } from './components/pages/user-infor/user-infor.component';
import { ChangePasswordComponent } from './components/pages/change-password/change-password.component';
import { AccessHistoryComponent } from './components/pages/access-history/access-history.component';
import { DangKyChiaSeComponent } from './components/pages/dang-ky-chia-se/dang-ky-chia-se.component';
import { LoginAdminComponent } from './components/pages/login-admin/login-admin.component';
import { LoginUserComponent } from './components/pages/login-user/login-user.component';
import { AdminPortalComponent } from './components/pages/admin-portal/admin-portal.component';
import { QthtComponent } from './components/pages/qtht/qtht.component';

const routes: Routes = [
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    { path: 'index', component: HomePortalComponent},
    {path: 'hdsearch', component: ServicesComponent},
    {path: 'hdaddacc', component: ServicesStyleTwoComponent},
    {path: 'hdtdv', component: ServicesStyleThreeComponent},
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'userInfor', component: UserInforComponent},
    {path: 'change-password', component: ChangePasswordComponent},
    {path: 'access-history', component: AccessHistoryComponent},
    {path: 'dangkychiase', component: DangKyChiaSeComponent},
    // Here add new pages component
    {path: 'login-user', component: LoginUserComponent},
    {path: 'login-admin', component: LoginAdminComponent},
    {path: 'admin-portal', component: AdminPortalComponent},
    {path: 'qtht', component: QthtComponent},
    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

const config: ExtraOptions = {
    useHash: true,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
