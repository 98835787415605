import { Component, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";
import { HomeDemoSixService } from '../home-portal/home-portal.service';
import { SUCCESS_RESPONSE } from 'src/app/common/contants';
import { IListAppModel } from 'src/app/common/app-qtht.service';

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.scss']
})
export class AdminPortalComponent implements OnInit {

  constructor(
    private service: HomeDemoSixService
  ) { }

  listApp: IListAppModel[] = [];

  ngOnInit() {
    this.getListAdminMenu();
  }

  path: string = environment.APP_HOME_PATH;
  
  WEB_QTHT: string = environment.WEB_ADMIN_QTHT;
  WEB_KHCN: string = environment.WEB_ADMIN_KHCN;
  WEB_LDTBXH: string = environment.WEB_ADMIN_LDTBXH;
  WEB_NV: string = environment.WEB_ADMIN_NV;
  WEB_TC: string = environment.WEB_ADMIN_TC;
  WEB_TTTT: string = environment.WEB_ADMIN_TTTT;
  WEB_TP: string = environment.WEB_ADMIN_TP;
  WEB_VHTT: string = environment.WEB_ADMIN_VHTT;
  WEB_GDDT: string = environment.WEB_ADMIN_GDDT;
  WEB_NN: string = environment.WEB_ADMIN_NN;
  WEB_XD: string = environment.WEB_ADMIN_XD;
  WEB_YT: string = environment.WEB_ADMIN_YT;
  WEB_CT: string = environment.WEB_ADMIN_CT;
  WEB_TNMT: string = environment.WEB_ADMIN_TNMT;
  WEB_NGOAIVU: string = environment.WEB_ADMIN_NGOAIVU;
  WEB_CONGBAODT: string = environment.WEB_ADMIN_CONGBAODT;
  WEB_DMDC: string = environment.WEB_ADMIN_DMDC;

  getListAdminMenu() {
    return this.service.getListMenuAdmin().subscribe((res) => {
      if(res.code == SUCCESS_RESPONSE){
        this.listApp = res.data
      }
    })
  }

  showAppPortal(madinhdanh: string) {
    const appPortal = this.listApp.find((app) => app.madinhdanhApp == madinhdanh);
    const showApp = appPortal ? true : false
    return showApp
  }
}
