import { Component, Inject, OnInit } from "@angular/core";
import { HomeDemoSixService } from "../home-portal/home-portal.service";
import { CommonService } from "src/app/common/common.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AppQthtApiService, InsertUpdateModel, SysuserEntity } from "src/app/common/app-qtht.service";
import { SUCCESS_RESPONSE, listSex } from "src/app/common/contants";
import { AuthService } from "src/app/common/auth.service";

@Component({
    selector: 'app-user-infor',
    templateUrl: './user-infor.component.html',
    styleUrls: ['./user-infor.component.scss']
})
export class UserInforComponent implements OnInit {
    constructor(
        private service: HomeDemoSixService,
        private commonService: CommonService,
        private router: Router,
        private qthtService: AppQthtApiService,
        private authService: AuthService,
    ) { }

    isEditing: boolean = false;
    path: string = environment.APP_HOME_PATH;
    listSex = [
        {
            value: 1,
            text: 'Nam'
        },
        {
            value: 0,
            text: 'Nữ'
        },
        {
            value: 2,
            text: 'Khác'
        }
    ]
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    ngOnInit(): void {
        this.loadData();
    }
    target = new SysuserEntity();

    user = this.authService.getUser();
    loadData() {
        this.qthtService.appQthtApiV1SysUserSelectOneCustom(this.user.UserId).subscribe(res => {
            if (res.code === SUCCESS_RESPONSE) {
                this.target = res.data;
            }
        })
    }

    save() {
        if(this.base64){
            this.target.imgavatar = this.base64
        }
        this.qthtService
            .appQthtApiV1SysUserUpdateUser(this.target)
            .subscribe((result) => {
                if (result.code === SUCCESS_RESPONSE) {
                    this.base64 = "";
                    this.isEditing = false;
                    this.commonService.toastrSuccess("cập nhật thành công");
                } else {
                    this.commonService.toastrDanger(result.message);
                }
            });
    }

    base64;
    showSelectedImage(event) {
        var selectedFile = event.target.files[0];
        var reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result as string;
            this.base64 = base64String;
        };

        reader.readAsDataURL(selectedFile);
    }

    editForm() {
        this.isEditing = true;
    }
}
