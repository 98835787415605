<app-navbar></app-navbar>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="row">
                <h2
                    style="
                        text-align: center;
                        font-family: Arial, Helvetica, sans-serif;
                    "
                >
                    HƯỚNG DẪN TẠO DỊCH VỤ
                </h2>
                <br>
                <br>
                <br>
        <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3"></div>
        <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
            <p style="font-size: 14px; font-family:Arial, Helvetica, sans-serif;">
                Bước 1: Truy cập trang chủ Cổng dữ liệu mở, click vào  <img style="width: 50px;" src="{{path}}/assets/img/services/dichvu-icon1.png"/> tại góc phải màn hình<br/>
                <img style="width: 708px;" src="{{path}}/assets/img/services/dichvu-1.jpg"/><br/>
                Bước 2: Điền đầy đủ thông tin để tạo dịch vụ<br/>
                <img style="width: 708px;" src="{{path}}/assets/img/services/dichvu-2.jpg"/><br/>
                -	Tên tài liệu: Tên của tài liệu sẽ tạo dịch vụ<br/>
                -	Mô tả dữ liệu: Mô tả về dữ liệu của tài liệu<br/>
                -	Họ tên: Họ tên người đăng ký<br/>
                -	Số điện thoại: Số điện thoại người đăng ký<br/>
                -	Email: Email người đăng ký<br/>
                -	Đơn vị công tác: Đơn vị công tác của người đăng ký<br/>
                -	Tick chọn đồng ý chịu trách nhiệm với thông tin đăng tải và chia sẻ dịch vụ<br/>
                Bước 3: Bấm nút <img style="width: 100px;" src="{{path}}/assets/img/services/dichvu-icon2.png"/> để gửi thông tin đăng ký lên Cổng dữ liệu
            </p>
        </div>
    </div>
</div>
</div>
</section>
<!-- End Contact Area -->
