<nb-layout>
 
  <nb-layout-header fixed>
  <!-- Insert header here -->
  </nb-layout-header>

  <nb-layout-column>
    <alert></alert>
    <app-preloader></app-preloader>
    <app-sidebar></app-sidebar>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <ngx-scrolltop></ngx-scrolltop>
    <button mat-fab class="fab-button" routerLink="/dangkychiase"><i class="fas fa-share-alt" style="color:#fff"></i>
    </button>
  </nb-layout-column>

  <nb-layout-footer fixed>
  <!-- Insert footer here -->
  </nb-layout-footer>

</nb-layout>
