<app-navbar></app-navbar>

<!-- Login Area -->

<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-sm-8 col-md-8 col-lg-8">
            <div class="text-center mt-5">
                <h3>ĐĂNG NHẬP CƠ SỞ DỮ LIỆU DÙNG CHUNG TỈNH TUYÊN QUANG</h3>
                <!-- <p>If you have an account with us, please log in.</p> -->
            </div>
            <div class="form-content mt-0">
                <form>
                    <div
                        class="form-group"
                        data-validate="Tài khoản không được để trống"
                    >
                        <label>Tên tài khoản</label>
                        <input
                            type="text"
                            class="form-control"
                            name="username"
                            placeholder="Tên Tài khoản"
                            [(ngModel)]="user.username"
                            focus-me="focusUsername"
                            keyup.enter="login()"
                            autocomplete="false"
                        />
                    </div>
                    <div
                        class="form-group"
                        data-validate="Mật khẩu không được để trống"
                    >
                        <label>Mật khẩu</label>
                        <input
                            class="form-control"
                            type="{{ inputType }}"
                            name="pass"
                            placeholder="Mật khẩu"
                            [(ngModel)]="user.password"
                            keyup.enter="login()"
                            autocomplete="false"
                        />
                        <span (click)="showPassword()">
                            <i
                                style="
                                    position: relative;
                                    float: right;
                                    top: -35px;
                                    right: 20px;
                                    cursor: pointer;
                                "
                                class="{{ showHideClass }}"
                            ></i>
                        </span>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group form-check">
                                <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">Remember me</label> -->
                            </div>
                        </div>
                        <div class="col-6 text-end">
                            <!-- <a class="form-link right" routerLink="/">Quên mật khẩu?</a> -->
                        </div>
                    </div>

                    <div
                        class="form-group d-flex"
                        style="justify-content: space-between"
                    >
                        <button
                            prevent-default
                            class="btn btn-success"
                            (click)="login()"
                            style="width: 45%"
                        >
                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                            Đăng nhập
                        </button>
                        <button
                            prevent-default
                            class="btn btn-success"
                            (click)="loginSSO()"
                            style="width: 45%"
                        >
                            <i class="fa fa-podcast" aria-hidden="true"></i>
                            Đăng nhập SSO
                        </button>
                    </div>
                </form>

                <div class="form-footer">
                    <p>
                        Không có tài khoản?
                        <a class="form-link" routerLink="/register">Đăng ký</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area -->

<div style="position: relative; max-width: 95vw">
    <div class="shape-img2">
        <img src="{{ path }}/assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="{{ path }}/assets/img/shape/shape3.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="{{ path }}/assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="{{ path }}/assets/img/shape/shape5.png" alt="image" />
    </div>
    <div class="shape-img7">
        <img src="{{ path }}/assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape-img8">
        <img src="{{ path }}/assets/img/shape/shape8.png" alt="image" />
    </div>
    <div class="shape-img9">
        <img src="{{ path }}/assets/img/shape/shape9.png" alt="image" />
    </div>
    <div class="shape-img10">
        <img src="{{ path }}/assets/img/shape/shape10.png" alt="image" />
    </div>
</div>
