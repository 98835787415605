<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<!-- <div class="page-title-area"></div> -->
<!-- End Page Title Area -->

<!-- Login Area -->

<div class="container">
    <div class="row justify-content-center">
        <div class="col-sm-10 col-md-10 col-lg-10 p-5 login-portal">
            <div class="row justify-content-center text-center">
                <div class="col-sm-10 col-md-5 col-lg-5 px-4">
                    <h4 class="mb-3">CỔNG DỮ LIỆU MỞ</h4>
                    <div class="bg-login-portal">
                        <img src="{{path}}/assets/img/png/users-icon.png" alt="image" class="mt-5 pointer" routerLink="/login-user">
                        <h4 class="mt-4 pointer" routerLink="/login-user">CÔNG DÂN/DOANH NGHIỆP ĐĂNG NHẬP</h4>
                    </div>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-10 col-md-5 col-lg-5 px-4">
                    <h4 class="mb-3">KHO DỮ LIỆU DÙNG CHUNG</h4>
                    <div class="bg-login-portal">
                        <img src="{{path}}/assets/img/png/admin-icon.png" alt="image" class="mt-5 pointer" routerLink="/login-admin">
                        <h4 class="mt-4 pointer" routerLink="/login-admin" >CÁN BỘ ĐĂNG NHẬP</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area -->
<div style="position: relative; max-width: 95vw">
    <div class="shape-img2">
        <img src="{{ path }}/assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="{{ path }}/assets/img/shape/shape3.svg" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="{{ path }}/assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="{{ path }}/assets/img/shape/shape5.png" alt="image" />
    </div>
    <div class="shape-img7">
        <img src="{{ path }}/assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape-img8">
        <img src="{{ path }}/assets/img/shape/shape8.png" alt="image" />
    </div>
    <div class="shape-img9">
        <img src="{{ path }}/assets/img/shape/shape9.png" alt="image" />
    </div>
    <div class="shape-img10">
        <img src="{{ path }}/assets/img/shape/shape10.png" alt="image" />
    </div>
</div>
