import { Injectable } from '@angular/core';
import { SUCCESS_NOTICE, TITLE_NOTICE } from './contants';
import { AlertService } from '../components/layouts/_alert';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public alertService: AlertService,
  ) { }
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  toastrSuccess(message?: string) {
    this.alertService.success(message , this.options);
  }

  toastrPrimary(message: string) {
    this.alertService.primary(message , this.options);
  }

  toastrWarning(message: string) {
    this.alertService.warn(message, this.options);
  }

  toastrDanger(message: string) {
    this.alertService.error(message, this.options);
  }

  toastrInfo(message: string) {
    this.alertService.info(message, this.options);
  }

  // toastrShow(message: string) {
  //   this.alertService.show(message, this.options);
  // }

  // toastrDefault(message: string) {
  //   this.alertService.default(message, this.options);
  // }
}
